<template>
  <div class="tack-container">
    <!-- 微信详情 -->
    <div class="weChat">
      <div class="weChat-image"></div>
      <div class="weChat-name">微信昵称</div>
      <div class="query-number">当前查询次数次</div>
    </div>
    <!-- 每日任务 -->
    <div class="tack-expected">
      <div class="tack-header">每日任务</div>
      <!-- 所有任务 -->
      <div class="tack-content">
        <!-- <div class="tack-content-item">
          <div class="tack-content-item-l">
            <div class="title">
              <div class="line"></div>
              <span>绑定手机号</span>
            </div>
            <p class="info">+3次机会 绑定手机号</p>
          </div>
          <div class="tack-content-item-r">
            <div class="tack-fulfil" @click="$router.push('/verification')">去完成</div>
          </div>
        </div> -->
        <div class="tack-content-item">
          <div class="tack-content-item-l">
            <div class="title">
              <div class="line"></div>
              <span>分享好友</span>
            </div>
            <p class="info">+3次机会 分享好友</p>
          </div>
          <div class="tack-content-item-r">
            <div class="tack-fulfil" @click="sharePopup">去完成</div>
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model="showShare"
      round
      position="bottom"
      :style="{ height: '15%' }"
    >
      <div class="share" @click="share">
        <img src="../../assets/images/1.png" alt="" />
        <span>分享给好友</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      showShare: false
    }
  },
  methods: {
    sharePopup() {
      this.showShare = true
    },
    share() {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: 'wx08fbe99d74609235', // 必填，公众号的唯一标识
        timestamp: '123', // 必填，生成签名的时间戳
        nonceStr: '123', // 必填，生成签名的随机串
        signature: '123', // 必填，签名
        jsApiList: ['onMenuShareAppMessage'] // 必填，需要使用的JS接口列表
      })
      wx.ready(function () {
        wx.onMenuShareAppMessage({
          title: 'title',
          desc: '分享内容',
          link: '分享链接',
          imgUrl: '分享显示图片',
          trigger: function trigger(res) { },
          success: function success(res) {
            console.log('已分享');
          },
          cancel: function cancel(res) {
            console.log('已取消');
          },
          fail: function fail(res) {
            alert(JSON.stringify(res));
          }
        })
      })
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  }

}
</script>
<style lang="less" scoped>
.tack-container {
  background-color: #f9faf9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  .weChat {
    position: relative;
    background: url("../../assets/images/task_bg.png") no-repeat;
    background-size: 100% 100%;
    height: 233px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .weChat-image {
      width: 81px;
      height: 81px;
      background: url("../../assets/images/hearder@3x.png") no-repeat;
      background-size: 81px 81px;
      margin-top: 37px;
      margin-bottom: 13px;
    }
    .weChat-name {
      color: #ffffff;
      font-size: 18px;
      text-align: center;
    }
    .query-number {
      position: absolute;
      bottom: -25px;
      width: 339px;
      height: 50px;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px rgba(234, 234, 234, 0.5);
      border-radius: 8px;
      font-size: 16px;
      color: #666;
      text-align: center;
      line-height: 50px;
    }
  }
  .tack-expected {
    margin-top: 64px;
    padding: 0 18px;
    color: #333;

    .tack-header {
      height: 40px;
      font-size: 18px;
      font-weight: 500;
    }
    .tack-content {
      .tack-content-item {
        display: flex;
        justify-content: space-between;
        padding-left: 14px;
        margin-bottom: 24px;
        .tack-content-item-l {
          .title {
            position: relative;
            font-size: 16px;
            .line {
              position: absolute;
              top: 4px;
              left: -14px;
              width: 4px;
              height: 14px;
              background-color: #3c65e1;
              border-radius: 2px;
            }
          }
          .info {
            font-size: 12px;
            color: #999999;
          }
        }
        .tack-content-item-r {
          margin-top: 14px;
          .tack-fulfil {
            width: 49px;
            height: 23px;
            background: #4169e2;
            border-radius: 12px;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            line-height: 23px;
          }
        }
      }
    }
  }
  .share {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
    }
    span {
      font-size: 18px;
      color: #666;
    }
  }
}
</style>